<template>
<div class="flex">
  <div class="sidebar bg-gray-800 transition-all duration-300">
    <div class="flex items-center justify-between h-full p-4">
      <div class="flex items-center space-x-4">
        <img id="header-logo" src="@/assets/icon/logo_dmi.png" alt="DMI" style="width: 28px; margin-right: 40px" />
        <ol class="flex space-y-2">
          <!-- 파일 메뉴 -->
          <li class="relative">
            <div @click="toggleDropdown('file')"
              class="flex items-center p-2 text-gray-300 dark:text-gray-300 hover:bg-gray-700 rounded-md cursor-pointer"
              style="margin-top: 7px; margin-right: 10px">
              <svg class="w-4 h-4 text-gray-300 dark:text-white mr-2" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path fill="currentColor"
                  d="M8 1V0v1Zm4 0V0v1Zm2 4v1h1V5h-1ZM6 5H5v1h1V5Zm2-3h4V0H8v2Zm4 0a1 1 0 0 1 .707.293L14.121.879A3 3 0 0 0 12 0v2Zm.707.293A1 1 0 0 1 13 3h2a3 3 0 0 0-.879-2.121l-1.414 1.414ZM13 3v2h2V3h-2Zm1 1H6v2h8V4ZM7 5V3H5v2h2Zm0-2a1 1 0 0 1 .293-.707L5.879.879A3 3 0 0 0 5 3h2Zm.293-.707A1 1 0 0 1 8 2V0a3 3 0 0 0-2.121.879l1.414 1.414ZM2 6h16V4H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v12h2V6h-2Zm0 12v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V6H0v12h2ZM2 6V4a2 2 0 0 0-2 2h2Zm16.293 3.293C16.557 11.029 13.366 12 10 12c-3.366 0-6.557-.97-8.293-2.707L.293 10.707C2.557 12.971 6.366 14 10 14c3.634 0 7.444-1.03 9.707-3.293l-1.414-1.414ZM10 9v2a2 2 0 0 0 2-2h-2Zm0 0H8a2 2 0 0 0 2 2V9Zm0 0V7a2 2 0 0 0-2 2h2Zm0 0h2a2 2 0 0 0-2-2v2Z" />
              </svg>
              <span>파일</span>
              <svg v-if="isDropdownOpen('file')" class="w-4 h-4 text-gray-500 dark:text-white ml-5" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
              </svg>
            </div>
            <transition name="fade">
              <ul v-if="isDropdownOpen('file')" class="absolute left-0 mt-2 w-48 bg-gray-800 shadow-lg rounded-md z-10">
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.NewFileonClick()">
                  새 파일
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.ImportGltfonClick()">
                  GLTF 불러오기
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.ExportGltfonClick()">
                  다른 이름으로 저장하기
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.window.addFileEditoronClick('example/Example_1.gltf')">
                  예제 1번 (Car Loop) 불러오기
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.window.addFileEditoronClick('example/Example_2.gltf')">
                  예제 2번 (SMT Line (PCB)) 불러오기
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.window.addFileEditoronClick('example/Example_3.gltf')">
                  예제 3번 (FlexiBowl) 불러오기
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.window.addFileEditoronClick('example/Example_4.gltf')">
                  예제 4번 (Car Door) 불러오기
                </li>
              </ul>
            </transition>
          </li>

          <!-- 에디터 메뉴 -->
          <li class="relative">
            <div @click="toggleDropdown('view')"
              class="flex items-center p-2 text-gray-300 dark:text-gray-200 hover:bg-gray-700 rounded-md cursor-pointer"
              style="margin-right: 10px">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5 text-gray-300 dark:text-white mr-2">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
              <span>에디터</span>
              <svg v-if="isDropdownOpen('view')" class="w-4 h-4 text-gray-500 dark:text-white ml-5" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
              </svg>
            </div>
            <transition name="fade">
              <ul v-if="isDropdownOpen('view')" class="absolute left-0 mt-2 w-48 bg-gray-800 shadow-lg rounded-md z-10">
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.editCloneonClick()">
                  복제(Copy)
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.editDeleteonClick()">
                  삭제(Del)
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.ViewBackgroundColoronClick()">
                  배경색 변경
                </li>
                <li @click="closeDropdown()"
                  class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.ViewFullscreenonClick()">
                  전체화면
                </li>
              </ul>
            </transition>
          </li>

          <!-- 도움말 메뉴 -->
          <li class="relative">
            <div @click="toggleDropdown('help')"
              class="flex items-center p-2 text-gray-300 dark:text-gray-200 hover:bg-gray-700 rounded-md cursor-pointer"
              style="margin-right: 10px">
              <svg class="w-4 h-4 text-gray-300 dark:text-white mr-3" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M5 5h9M5 9h5m8-8H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h4l3.5 4 3.5-4h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
              </svg>
              도움말
              <svg v-if="isDropdownOpen('help')" class="w-4 h-4 text-gray-500 dark:text-white ml-5" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
              </svg>
            </div>
            <transition name="fade">
              <ul @click="closeDropdown()" v-if="isDropdownOpen('help')"
                class="absolute left-0 mt-2 w-48 bg-gray-800 shadow-lg rounded-md z-10">
                <li class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.helpManualonClick()">
                  홈페이지 방문
                </li>
              </ul>
            </transition>
          </li>

          <!-- 원격지원 메뉴 -->
          <li class="relative">
            <div @click="toggleDropdown('support')"
              class="flex items-center p-2 text-gray-300 dark:text-gray-200 hover:bg-gray-700 rounded-md cursor-pointer"
              style="margin-right: 10px">
              <svg class="w-4 h-4 text-gray-300 dark:text-white mr-3" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 19">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M19.287 5H15m3.852 3H15m3.384 3H15m-9.47-.764h-.972A14.755 14.755 0 0 1 4.445 8c-.019-.747.019-1.494.113-2.236h.972a.95.95 0 0 0 .946-.856l.188-1.877a.951.951 0 0 0-.946-1.046H3.791a1.127 1.127 0 0 0-1.067.75A16.11 16.11 0 0 0 2 8a16.737 16.737 0 0 0 .743 5.242c.154.463 1.255.773 1.743.773h1.232a.95.95 0 0 0 .946-1.046l-.188-1.877a.95.95 0 0 0-.946-.856ZM19.063 2H10v12h8.273l1.716-10.847A.981.981 0 0 0 19.063 2ZM20 18H9v-2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v2Z" />
              </svg>
              <span>원격지원</span>
              <svg v-if="isDropdownOpen('support')" class="w-4 h-4 text-gray-500 dark:text-white ml-5"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
              </svg>
            </div>
            <transition name="fade">
              <ul @click="closeDropdown()" v-if="isDropdownOpen('support')"
                class="absolute left-0 mt-2 w-48 bg-gray-800 shadow-lg rounded-md z-10">
                <li class="text-left text-gray-300 dark:text-gray-200 hover:bg-gray-600 rounded-md cursor-pointer p-2"
                  onclick="window.remoteRemote_supportonClick()">
                  원격 지원 연결
                </li>
              </ul>
            </transition>
          </li>

          <!-- 이슈사항 메뉴 -->
          <li>
            <a @click="
              emitToggleIssue();
            closeDropdown();
            " class="flex items-center p-2 text-gray-300 dark:text-gray-200 hover:bg-gray-700 rounded-md cursor-pointer"
              style="margin-right: 10px">
              <svg class="w-4 h-4 text-gray-300 dark:text-white mr-3" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 19">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m9 12 5.419 3.871A1 1 0 0 0 16 15.057V2.943a1 1 0 0 0-1.581-.814L9 6m0 6V6m0 6H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h7m-5 6h3v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-5Zm15-3a3 3 0 0 1-3 3V6a3 3 0 0 1 3 3Z" />
              </svg>
              <span>이슈사항</span>
            </a>
          </li>
          <li>
            <!-- 
              <a
                class="flex items-center p-2 text-gray-300 dark:text-gray-200 hover:bg-gray-700 rounded-md cursor-pointer"
                @click="
                  emitToggleBentoGrid();
                  closeDropdown();
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                환경설정
              </a>
              이슈사항 메뉴 -->
          </li>
          <!-- 프로필 -->
        </ol>
      </div>
      <!--
        <div class="fixed right-0 top-0 mt-8 mr-8">
          <div class="flex items-center justify-start space-x-2 text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
            <span class="text-gray-300 dark:text-white text-xl font-semibold">
              user
            </span>
          </div>
        </div>
        -->
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "NavSide",
  data() {
    return {
      dropdownOpen: null,
    };
  },
  methods: {
    toggleDropdown(menuName) {
      if (this.dropdownOpen === menuName) {
        this.dropdownOpen = null;
      } else {
        this.dropdownOpen = menuName;
      }
    },
    isDropdownOpen(menuName) {
      return this.dropdownOpen === menuName;
    },
    closeDropdown() {
      this.dropdownOpen = null;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeDropdown();
      }
    },
    emitToggleBentoGrid() {
      this.$emit("toggle-bento-grid");
    },
    emitToggleIssue() {
      this.$emit("toggle-issue");
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.sidebar {
  width: 100%;
  height: 55px;
  transition: width 0.3s;
  border-radius: 8px;
  /* 위쪽 모서리 둥글게 */
  background-color: rgba(40, 40, 40, 0.8);
  /* 배경색을 반투명하게 설정 */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fixed {
  position: fixed;
}
</style>
